.form {
	display: block;
	margin: 0;
	padding: 0;
}

.form .wrapper {
	margin: 0;
	padding: 0;
	overflow: hidden;
	border-radius: 10px;
	background-color: var(--white);
}

.form .error {
	margin: 15px 0;
	padding: 10px 25px;
	font-size: 9pt;
	line-height: 20px;
	text-align: left;
	color: var(--white);
	background-color: var(--red);
	border-radius: 20px;
}
.form .valid {
	margin: 15px 0;
	padding: 10px 25px;
	font-size: 9pt;
	line-height: 20px;
	text-align: left;
	color: var(--white);
	background-color: var(--mainColor);
	border-radius: 20px;
}

.form .containerInput {
	display: block;
	margin: 0;
	padding: 0;
	height: 40px;
	transition: all .2s;
}
.form .containerInput.flex {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	height: 60px;
}

.form .containerInput:focus-within {
	background-color: rgba(0, 140, 79, 0.1);
}

.form .containerInput > .icon {
	display: block;
	width: 24px;
	height: 24px;
	margin: 18px 25px;
	padding: 0;
}
.form .containerInput > .icon > svg {
	fill: var(--lightGray);
	transition: all .2s;
}
.form .containerInput:focus-within > .icon > svg {
	fill: var(--mainColor);
}

.form .containerInput > .input {
	width: 100%;
	display: block;
	margin: 0;
	padding: 0;
}

.form .containerInput .label {
	display: block;
	height: 15px;
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 15px;
	text-align: left;
	font-family: "NotoSansJP-Regular", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
	color: var(--darkGray);
}
.form .containerInput.flex .label {
	margin-top: 10px;
}

.form .containerInput .inputText {
	display: block;
	width: 100%;
	height: 25px;
	margin: 0;
	padding: 0;
}
.form .containerInput .inputText > input {
	width: 100%;
	margin: 0;
	padding: 0;
	font-size: 10pt;
	border: none;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: transparent;
	outline: none;
	transition: all .25s;
}

.form .belowLogin {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: 20px 0 0 0;
}

.form .labelCheckbox {
	float: left;
	display: flex;
	margin: 0;
	padding: 0;
}
.form .labelCheckbox > #rememberMe {
	display: block;
	margin: 0 10px 0 0;
}
.form .labelCheckbox > p {
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 20px;
	text-align: left;
	color: var(--darkGray);
	cursor: pointer;
	transition: all .2s;
}
.form .labelCheckbox:hover > p {
	color: var(--blacky);
}
.form .labelCheckbox > input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.form .labelCheckbox > .checkmark {
	height: 22px;
	width: 22px;
	margin: 0 10px 0 0;
	background-color: var(--whity);
	border-radius: 5px;
	transition: all .15s;
}
.form .labelCheckbox:hover > input ~ .checkmark {
	background-color: var(--whity);
}
.form .labelCheckbox > input:checked ~ .checkmark {
	background-color: var(--mainColor)
}
.form .labelCheckbox > .checkmark:after {
	content: "";
	display: none;
}
.form .labelCheckbox > input:checked ~ .checkmark:after {
	display: block;
}
.form .labelCheckbox .checkmark:after {
	margin: 4.5px 0 0 8.5px;
	width: 4px;
	height: 8px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.form .forgot {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 20px;
	text-align: right;
	color: var(--darkGray);
	text-decoration: none;
	transition: all 0.15s;
}
.form .forgot:hover {
	color: var(--blacky);
	text-decoration: underline;
}

.form .submit {
	display: block;
	margin: 20px 0 0 0;
	padding: 10px 25px;
	font-size: 9pt;
	line-height: 20px;
	font-family: "NotoSansJP-SemiBold", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
	text-align: left;
	color: var(--white);
	background-color: var(--mainColor);
	border-radius: 20px;
	border: none;
	cursor: pointer;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	transition: all .15s;
}
.form .submit.disable {
	cursor: not-allowed;
	opacity: 0.5;
	filter: alpha(opacity=50);
}
.form .submit.right {
	float: right;
}
.form .submit.disable {

}
.form .submit:not(.disable):hover {
	background-color: var(--secondaryColor);
}
.form .submit:not(.disable):active {
	background-color: var(--mainColorDark);
}
