/* For Mobile */
@media screen and (max-width: 767px) {
	.box {
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
	.box > .presentation {
		display: none;
	}
}

/* For Tablet */
@media screen and (min-width: 768px) {
	.box {
		top: 50%;
		left: 50%;
		width: calc(100vw - 150px);
		height: calc(100vh / 1.5);
		min-height: 500px;
		transform: translate(-50%, -50%);
		border-radius: 20px;
		box-shadow: 0 0 15px var(--lightGray);
		transition: all .25s;
	}
	.box > .presentation {
		display: none;
	}
}

/* For Desktop */
@media screen and (min-width: 992px) {
	.box {
		top: 50%;
		left: 50%;
		width: calc(100vw - 150px);
		max-width: 1600px;
		height: calc(100vh / 1.5);
		min-height: 500px;
		transform: translate(-50%, -50%);
		border-radius: 20px;
		box-shadow: 0 0 15px var(--lightGray);
		transition: all .25s;
	}
	.box > .presentation {
		display: block;
	}
}