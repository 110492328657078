@font-face {
	font-family: "NotoSansJP-Black";
	src: url("font/NotoSansJP/NotoSansJP-Black.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-Bold";
	src: url("font/NotoSansJP/NotoSansJP-Bold.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-ExtraBold";
	src: url("font/NotoSansJP/NotoSansJP-ExtraBold.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-ExtraLight";
	src: url("font/NotoSansJP/NotoSansJP-ExtraLight.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-Light";
	src: url("font/NotoSansJP/NotoSansJP-Light.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-Medium";
	src: url("font/NotoSansJP/NotoSansJP-Medium.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-Regular";
	src: url("font/NotoSansJP/NotoSansJP-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-SemiBold";
	src: url("font/NotoSansJP/NotoSansJP-SemiBold.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-Thin";
	src: url("font/NotoSansJP/NotoSansJP-Thin.ttf") format("truetype");
}

:root {
	--mainColor: #008C4F;
	--mainColorDark: #00693b;
	--secondaryColor: #00BF6C;
	--darkGray: #A7A7A7;
	--lightGray: #ababab;
	--black: #000000;
	--blacky: #202020;
	--white: #ffffff;
	--whiteLess: #fafafa;
	--whity: #D2D2D2;
	--whityLess: #c9c9c9;
	--red: #ab4343;
	--logo: url(../image/logo/hivy_green.svg);
}
