html, body {
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	font-family: "NotoSansJP-Regular", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--whiteLess);
}

.loader {
	display: block;
}
.loader.submitForm {
	width: 20px;
	height: 20px;
	margin: 0 15px;
	padding: 0;
}

.container {
	display: block;
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: var(--whity);
    background-image: url(../image/resource/blob-haikei.svg);
	background-size: cover;
}

.box {
	position: fixed;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	background-color: var(--whiteLess);
}
.box > .presentation {
	display: block;
	width: 70%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	border-radius: 20px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-image: url(../image/resource/team-reduce.jpg);
}
.box > .formPart {
	margin: 0;
	padding: 0;
}
.box > .formPart > h1 {
	margin: 50px 50px 0 50px;
	padding: 0;
	font-size: 22pt;
	text-align: left;
	font-family: "NotoSansJP-Thin", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
}
.box > .formPart > p {
	margin: 0 50px 0 50px;
	padding: 15px 0 0 0;
	font-size: 10pt;
	text-align: left;
	font-family: "NotoSansJP-Regular", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
}
.box > .formPart > .containerForm {
	margin: 50px 50px 0 50px;
	padding: 0;
}